<template>
  <div class="px-4 pt-4 lg:!px-10 lg:!pt-6">
    <ImageSlider :display-section-banner="displaySectionBanner" />
    <div class="mb-8 flex gap-2 lg:!mb-12">
      <ListLinkCategory
        :categories-ref="categorySection"
        :mainRoute="mainRoute"
        :mainHomeLabel="mainHomeLabel"
        :mainIcon="mainIcon"
        class="flex-2 md:max-w-[75%]"
        :selectedCategory="selectedCategory"
        @updateData="setSelectedCategory"
      />
      <InputSearch
        :search="search"
        :on-search="setSearchValue"
        :placeholder="$t(searchPlaceholder)"
      />
    </div>

    <div v-if="selectedCategory || search">
      <SearchGameSection
        :search="search"
        :selected-category="selectedCategory"
      />
    </div>

    <div class="min-h-[500px]" v-else>
      <LazyBiggestWin
        v-if="showExtraSections && token"
        class="mb-8 overflow-hidden lg:!mb-12"
      />
      <template
        v-for="category in categoriesPosition.categoriesTop"
        :key="category.id"
      >
        <LazySlideViewAll
          :key="category.id"
          v-if="category.games?.length"
          :list-data="category.games"
          class="mb-8 overflow-hidden lg:mb-12"
          :slug="category.slug"
          :name="category.name"
          :type="topSection"
          :img="category.image"
        />
      </template>
      <ClientOnly v-if="token && showExtraSections">
        <LazyPlayAgain class="mb-8 overflow-hidden lg:!mb-12" />
        <LazyLobbyFavorite class="mb-8 overflow-hidden lg:!mb-12" />
        <LazyProviderList class="mb-8 overflow-hidden lg:!mb-12" />
      </ClientOnly>
      <template
        v-for="category in categoriesPosition.categoriesBottom"
        :key="category.id"
      >
        <LazySlideViewAll
          :key="category.id"
          v-if="category.games?.length"
          :list-data="category.games"
          class="mb-8 overflow-hidden lg:mb-12"
          :slug="category.slug"
          :name="category.name"
          :type="bottomSection"
          :img="category.image"
          is-bottom
        />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import InputSearch from "~/components/lobby/InputSearch.vue";
import SearchGameSection from "~/components/lobby/SearchGameSection.vue";
import { DISPLAY_SECTION } from "~/config/enum";
import { useGetGameCategoriesWithGames } from "~/queries/gameService";
import { GameType, IGameCategoryWithGame } from "~/types/schema";
import ImageSlider from "~/components/lobby/ImageSlider.vue";

type Props = {
  mainRoute: string;
  mainHomeLabel: string;
  mainIcon?: string;
  displaySectionBanner: string;
  topSection: DISPLAY_SECTION;
  bottomSection: DISPLAY_SECTION;
  navSection: string;
  showExtraSections?: boolean;
  type: GameType;
};

const props = defineProps<Props>();

const token = useToken();

const { data: categories } = useGetGameCategoriesWithGames(props.type);

const categoriesPosition = computed(() => {
  const categoriesTop: IGameCategoryWithGame[] = [];
  const categoriesBottom: IGameCategoryWithGame[] = [];
  categories.value?.forEach((category) => {
    if (
      category.display_sections.includes(props.topSection) &&
      !category.display_sections.includes(props.bottomSection)
    ) {
      categoriesTop.push(category);
    }
    if (category.display_sections.includes(props.bottomSection)) {
      categoriesBottom.push(category);
    }
  });
  return {
    categoriesTop,
    categoriesBottom,
  };
});

const categorySection = computed(() => {
  return categories.value?.filter((category) =>
    category.display_sections.includes(props.navSection)
  );
});

const route = useRoute();
const selectedCategory = ref(String(route.query.type || ""));
const search = ref(String(route.query.keyword || ""));

const handleNavigate = (keyword: string, type: string) => {
  const query = {
    keyword: keyword || undefined,
    type: type || undefined,
  };
  navigateTo({ path: props.mainRoute, query });
};

const setSearchValue = _debounce((event: any) => {
  search.value = event.target.value;
  handleNavigate(search.value, selectedCategory.value);
});

const setSelectedCategory = (category: string) => {
  selectedCategory.value = category;
  handleNavigate(search.value, category);
};
const searchPlaceholder = computed(() => {
  return selectedCategory?.value
    ? "common.btn.search"
    : "common.btn.search_all_games";
});

onBeforeUnmount(() => {
  scrollToTop();
});
</script>
<style scoped lang="scss">
.horizontal {
  cursor: grabbing;
  user-select: none;
}

:deep(.v-text-field) {
  .v-field {
    border-radius: 12px;
    height: 45px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
  }

  .v-field__input {
    input {
      margin-top: 0;
      margin-left: 20px;
      font-size: 14px;
      line-height: 150%;
    }

    input::-ms-input-placeholder {
      /* Edge 12-18 */
      color: #afa8bf;
    }

    input::placeholder {
      color: #afa8bf;
    }
  }
}

:deep(.v-field__input) {
  min-height: 45px;
  height: 45px;
  padding: 12px 16px;
  opacity: 1;
}

:deep(.v-locale--is-ltr) {
  &.v-field--variant-outlined,
  .v-field--variant-outlined {
    .v-field__outline__start {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      height: 45px;
    }

    .v-field__outline__end {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      height: 45px;
    }
  }
}

:deep(swiper-container) {
  height: 500px;
  @media (min-width: 1024px) {
    height: 364px;
  }
}

:deep(.v-input) {
  input {
    font-size: 17px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: #afa8bf;
  }
}

:deep(.box-search) {
  input {
    color: #270c4e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
</style>
