<template>
  <template v-if="gameListRef.length">
    <div class="flex flex-col gap-4">
      <div
        class="grid grid-cols-2 gap-4 xs:grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 xxl:grid-cols-8"
      >
        <div v-for="game in gameListRef" :key="game.id" class="flex flex-col">
          <SlideViewGame :game="game" :slug="selectedCategory" />
        </div>
      </div>
    </div>
    <LazyLoadMoreData
      v-if="selectedCategory || search"
      :selected-category="selectedCategory"
      :keyword="search"
      :game-list="gameListRef"
      :allow-fetch="true"
      @update-game-list="
        (newList: any) => {
          gameListRef = newList;
          loadingMore = false;
        }
      "
      :loading-more="loadingMore"
      @set-loading-more="(value: boolean) => (loadingMore = value)"
    />
  </template>
  <!-- if loading end and don't have data -->
  <div
    v-else
    class="-mx-2 mb-8 flex flex-wrap transition-all duration-200 lg:!mb-12 xl:-mx-3 xl:!flex-nowrap"
  >
    <div
      class="mx-2 flex size-full flex-col items-center justify-center rounded-2xl bg-main-150 py-12"
    >
      <img
        src="/images/icon/no-find.png"
        alt="icon-not-found"
        class="mb-[24px]"
      />
      <span class="text-sm text-white">
        {{ $t("common.message.no_game_found") }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useGameStore } from "~/stores/gameStore";
import { resCategoryGameList } from "~/types/schema";

type Props = {
  selectedCategory: string;
  search: string;
};
const props = defineProps<Props>();
const loadingMore = ref(false);

const gameListRef = ref<resCategoryGameList[] | []>([]);

const { getGameByCategory } = useGameStore();

watch(
  [() => props.search, () => props.selectedCategory],
  async ([currentSearch, currentSelectedCategory]) => {
    if (currentSearch || currentSelectedCategory) {
      gameListRef.value = await getGameByCategory(
        currentSelectedCategory,
        1,
        false,
        currentSearch
      );
    }
  },
  {
    immediate: true,
  }
);
</script>
