<template>
  <div>
    <div ref="container" class="keen-slider">
      <div
        v-for="(slide, index) in sliders"
        :key="slide.id"
        :class="`number-slide${index + 1}`"
        class="keen-slider__slide pt-2"
        style="height: unset"
      >
        <NuxtLink
          draggable="false"
          :to="slide.urlRedirect ?? '#'"
          class="block h-full cursor-pointer select-none transition-all duration-300 hover:-translate-y-2"
          :alt="slide.title || ''"
          :aria-label="slide.title || 'banner'"
        >
          <NuxtImg
            :src="slide.srcPc"
            :alt="slide.title || ''"
            class="!aspect-[9/5] w-full overflow-hidden rounded-lg object-cover"
            loading="eager"
            quality="80"
          />
        </NuxtLink>
      </div>
    </div>
    <div v-if="slider && dotHelper.length > 3" class="box-center hidden gap-6 pt-6 md:flex">
      <button
        v-for="(_slide, idx) in dotHelper"
        @click="() => update(idx)"
        :class="{
          '!size-3 !bg-main-pink': current === idx,
          'md:hidden': idx === dotHelper.length - 1,
          'lg:hidden': idx === dotHelper.length - 2,
        }"
        class="size-2 bg-white"
        :key="idx"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { BREAKPOINT } from "~/config/breakpoints";

type Props = {
  sliders: any[];
};

const props = defineProps<Props>();
const current = ref(0);
const [container, slider] = useKeenSlider({
  loop: false,
  slides: {
    perView: 1.2,
    spacing: 16,
  },
  breakpoints: {
    [`(min-width: ${BREAKPOINT.md})`]: {
      slides: { perView: 2, spacing: 16 },
    },
    [`(min-width: ${BREAKPOINT.lg})`]: {
      slides: { perView: 3, spacing: 16 },
    },
  },
  initial: current.value,
  slideChanged: (s) => {
    current.value = s.track.details.rel;
  },
});
const dotHelper = computed(() => {
  return slider.value ? [...Array(props.sliders.length)] : [];
});

const update = (idx: number) => {
  slider.value?.moveToIdx(idx, true);
};
</script>
