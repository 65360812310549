<template>
  <ClientOnly>
    <template #fallback>
      <div
        class="mb-8 grid grid-cols-[auto_14%] gap-4 md:grid-cols-2 lg:grid-cols-3"
        v-if="sliders.length"
      >
        <div
          v-for="(slide, index) in sliders.slice(0, 3)"
          :key="slide.id"
          class="pt-2"
          style="height: unset"
          :class="{ 'max-lg:hidden': index > 1 }"
        >
          <NuxtLink
            draggable="false"
            :to="slide.urlRedirect ??'#'"
            class="block h-full cursor-pointer select-none transition-all duration-300 hover:-translate-y-2"
            :aria-label="slide.title || 'banner'"
          >
            <NuxtImg
              :src="slide.srcPc"
              :alt="slide.title || ''"
              :key="slide.id"
              class="!aspect-[9/5] w-full overflow-hidden rounded-lg object-cover"
              loading="eager"
              quality="80"
              :class="{
                'max-md:h-full max-md:rounded-r-none max-md:object-cover':
                  index === 1,
              }"
            />
          </NuxtLink>
        </div>
      </div>
    </template>
    <HomeBannerCarousel
      v-if="sliders.length"
      :sliders="sliders"
      class="mb-8 w-full overflow-hidden"
    />
  </ClientOnly>
</template>
<script lang="ts" setup>
import { DISPLAY_STATUS } from "~/config/enum";
import { useGetListImageSliders } from "~/queries/gameService";

type Props = {
  displaySectionBanner: string;
};
const props = defineProps<Props>();
const { data: rawSliders } = useGetListImageSliders();
const token = useToken()

const sliders = computed((): any[] => {
  const displayStatus = token.value
    ? DISPLAY_STATUS.LOGGED_IN
    : DISPLAY_STATUS.LOGGED_OUT;
  return (rawSliders.value || []).reduce((accumulator: any, slideItem: any) => {
    if (
      slideItem.display_sections.includes(props.displaySectionBanner) &&
      slideItem.display_statuses.includes(displayStatus)
    ) {
      accumulator.push({
        id: slideItem.id,
        title: slideItem.title,
        content: slideItem.description,
        srcPc: slideItem.image_url,
        srcSp: slideItem.image_url,
        urlRedirect: slideItem.redirect_url,
        button: slideItem.button_text,
      });
    }
    return accumulator;
  }, []);
});

</script>
